.newentrybutton {
    position: absolute;
    z-index: 1;
    right: 6%;
    bottom: 5%;
    cursor: pointer;

    width: 60px;
    height: 60px;
    padding: 0;

    border: none;
    border-radius: 100%;

    background-color: var(--new_entry_button_background);
}

.newentrybuttontext {
    font-size: 40px;
    font-weight: 400;
    color: var(--new_entry_button_text);
    margin: auto;
}

.newentrybuttontip {
    position: absolute;
    z-index: 1;
    right: calc(6% + 70px);
    bottom: calc(5% + 12px);
    font-size: 14px;
    font-weight: 100;
    text-align: center;
    padding: 2px;
    padding-right: 4px;
    padding-left: 4px;
    border-radius: 6px;

    background-color: var(--new_entry_button_background);
}

.newentrybutton:hover {
    background-color: var(--new_entry_button_background_on_hover);
}

.newentrybutton:hover .newentrybuttontip {
    background-color: beige;
}

.newentrybutton:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px rgba(0, 0, 0, 0.25)
}