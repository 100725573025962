.calendar {
    width: calc(100% - 20px);
    height: 100%;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.year {

}

.yeartitle {

}

.months {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
}

.month {
    flex-shrink: 0;
    flex-grow: 0;
    width: calc((100% - ((6 - 1) * 40px) - ((6 * 2) * 1px)) / 6);
    height: min-content;
}

@media (max-width: 1900px) {
    .month {
        width: calc((100% - ((5 - 1) * 40px) - ((5 * 2) * 1px)) / 5);
    }
}

@media (max-width: 1600px) {
    .month {
        width: calc((100% - ((4 - 1) * 40px) - ((4 * 2) * 1px)) / 4);
    }
}

@media (max-width: 1400px) {
    .month {
        width: calc((100% - ((3 - 1) * 40px) - ((3 * 2) * 1px)) / 3);
    }
}

@media (max-width: 800px) {
    .month {
        width: calc((100% - ((2 - 1) * 30px) - ((2 * 2) * 1px)) / 2);
    }
}

@media (max-width: 500px) {
    .month {
        width: calc((100%) / 1);
    }
}

@media (min-width: 500px) {
    .months {
        gap: 30px;
    }
}

@media (min-width: 800px) {
    .months {
        gap: 40px;
    }
}

.monthtitle {
    text-align: center;
    padding-bottom: 15px;
}

.days {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.day {
    width: calc(100% / 7);
    aspect-ratio: 1;
    text-align: center;
    position: relative;
    cursor: default;
}

.smile {
    position: absolute;
    object-fit: cover;
    max-width: calc(80% - 3px);
    max-height: calc(80% - 3px);
    aspect-ratio: 1;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%);
    border-radius: 100%;
}