.uploadscreen {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    width: 95vw;
    height: calc(95vw * (4 / 3));
    max-height: 95vh;
    
    background-color:var(--upload_screen_background);
    border-radius: 10px;
    overflow: hidden;
}

.uploadscreenclosebutton {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    top: 0.5%;
    right: 0.5%;
    color: var(--upload_screen_close_button_text);
    background-color: var(--upload_screen_close_button_background);
    border: solid 1px var(--upload_screen_close_button_border);
    border-radius: 10px;
    font-weight: bold;
}

.uploadscreenclosebutton:hover {
    background-color: var(--upload_screen_close_button_on_hover);
}

.uploadscreenclosebutton:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px rgba(0, 0, 0, 0.25)
}

.uploadimage {
    position: absolute;
    max-width: 100%;
    max-height: 100%;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.uploadform {
    position: absolute;
    z-index: 1;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;

}

.uploadlabel {
    width: 190px;
}

.uploadbutton {
    cursor: pointer;
    margin-left: 3px;
}