.navbar {
    background-color: var(--navbar_background);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;

    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.smilespage {
    position: absolute;
    top: calc(70px);
    left: 0;
    width: 100%;
    height: calc(100% - 70px);
    overflow: hidden;
}