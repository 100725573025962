:root {
    font-family: 'Vazir', sans-serif;

    --background: wheat;
    color: black;

    --navbar_background: #d69a77;
    --navbar_logout_button_background: #c48b6b;
    --navbar_logout_button_on_hover: #8f5e41;
    --navbar_logout_button_border: black;
    --navbar_logout_button_text: black;

    --alert_background: antiquewhite;
    --alert_border: #c48b6b;
    --alert_text: black;
    --alert_close_button_background: #c48b6b;
    --alert_close_button_background_on_hover: #8f5e41;
    --alert_close_button_border: black;
    --alert_close_button_text: black;

    --login_form_border: #66422e;

    --new_entry_button_background: #ffcaab;
    --new_entry_button_background_on_hover:#e0b297;
    --new_entry_button_text: #53250a;

    --upload_screen_background: wheat;

    --upload_screen_close_button_background: #ffcaab;
    --upload_screen_close_button_border: black;
    --upload_screen_close_button_on_hover:#e0b297;
    --upload_screen_close_button_text: #53250a;

    --daily_smiles_return_button_background: #c48b6b;
    --daily_smiles_return_button_border: black;
    --daily_smiles_return_button_on_hover: #8f5e41;
    --daily_smiles_return_button_text: black;

    --daily_smiles_smile_background: none;

    --daily_smiles_photo_delete_button_background: #d69a77;
    --daily_smiles_photo_delete_button_on_hover: #8f5e41;
    --daily_smiles_photo_delete_button_text: black;

    --daily_smiles_photo_label_background: rgba(226, 122, 37, 0.4);
}

::-webkit-scrollbar-thumb {
    background-color: #3f3f46;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar {
    background-color: transparent;
}

.background {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: var(--background);
}

.alerts {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 4;
    width: 100%;
    transform: translateX(-50%);

    display: flex;
    flex-flow: column nowrap;
}