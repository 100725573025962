.page {
    width: calc(100% - 20px);
    height: 100%;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.returnbutton {
    margin-top: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    color: var(--daily_smiles_return_button_text);
    border: solid 2px var(--daily_smiles_return_button_border);
    border-radius: 15px;
    font-size: large;
    font-weight: bold;

    background-color: var(--daily_smiles_return_button_background);
}

.returnbutton:hover {
    background-color: var(--daily_smiles_return_button_on_hover);
}

.returnbutton:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px rgba(0, 0, 0, 0.25)
}

.smiles {
    display: flex;
    flex-flow: row wrap;
    gap: 4vh 2vw;
    justify-content: center;
}

.smile {
    flex-shrink: 0;
    flex-grow: 0;
    position: relative;
    height: calc(90vh / 5);
    width: calc((90vh / 5) * (3 / 4));
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--daily_smiles_smile_background);
}

.deletebutton {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 1%;
    right: 1%;
    border-radius: 100px;
    border: none;
    font-weight: bold;
    background-color: var(--daily_smiles_photo_delete_button_background);
    color: var(--daily_smiles_photo_delete_button_text);
}

.deletebutton:hover {
    background-color: var(--daily_smiles_photo_delete_button_on_hover);
}

.deletebutton:focus {
    outline: none;
    box-shadow: 
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px rgba(0, 0, 0, 0.25)
}

.photo {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.label {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    text-align: center;
    font-size: 12px;
    background-color: var(--daily_smiles_photo_label_background);
    border-radius: 10px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
}